import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Receipt = _resolveComponent("Receipt")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_Receipt, {
      isNew: false,
      clientId: _ctx.clientId,
      receiptId: _ctx.receiptId
    }, null, 8, ["clientId", "receiptId"])
  ]))
}